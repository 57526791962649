<template>
  <div class="getCode" v-text="msg"></div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { getCode,getAliUserId } from "_API_/api.services";
import { setStore, getStore, removeStore } from "@/utils/store";

export default {
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      code: "", //需要传给后台的code
      openId: "",
      msg: ""
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {};
    onMounted(async () => {
      //获取用户信息
      const info = store.state.user.userInfo;
      let oldRoute = getStore({ name: 'router' });
      // let openId = getStore({ name: 'userOpenId' });
      // if (openId && openId != 0) {
      //   return router.replace({
      //     name: oldRoute || 'personal-packageRecharge',
      //   });
      // }
      if (info.device === 'wx') {
        //传code获取openId
        getCode({
          code: route.query.code || 0,
          appId: info.isConfigAppid ? info.configAppid : info.appid,
        }).then(({ data }) => {
          if (data.code == 0) {
            state.openId = data.openId;
            // 存储openId到sessionStorage
            setStore({
              name: 'userOpenId',
              content: {
                openId: state.openId || '0',
              },
              type: 'session',
            });
						info.isConfigAppid ? store.dispatch("SetUserInfo", {isPackage: true}) : store.dispatch("SetUserInfo", {isPackage: false})
            // 路由跳转到存储在sessionStorage的router
            router.replace({
              name: oldRoute || 'personal-packageRecharge',
            });
            // 删除存储在sessionStorage的router
            removeStore({ name: 'router', type: 'session' });
            return;
          }
          tool.toast(
            {
              msg: data.msg,
              duration: 1500,
            },
            () => {
              router.replace({
                name: 'personal',
              });
            }
          );
        });
      } else if (info.device === 'ali') {
        let { data } = await getAliUserId({
          accessToken: route.query.auth_code,
          appId: info.aliAppId
        });
        if (data.code == 0) {
          state.openId = data.userId;
          // 存储openId到sessionStorage
          setStore({
            name: 'userOpenId',
            content: {
              openId: state.openId || '0',
            },
            type: 'session',
          });
          // 路由跳转到存储在sessionStorage的router
          router.replace({
            name: oldRoute || 'personal-packageRecharge',
          });
          // 删除存储在sessionStorage的router
          removeStore({ name: 'router', type: 'session' });
          return;
        }
        tool.toast(
          {
            msg: data.msg,
            duration: 1500,
          },
          () => {
            router.replace({
              name: 'personal',
            });
          }
        );
      }
    });
    return { ...toRefs(state), ...methods };
  },
};
</script>

<style lang="scss" scoped>
.getCode {
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}
</style>
